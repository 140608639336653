'use strict';

const $_firebase = require("./firebase");

const JSZip = require("jszip");
const saveAs = require("file-saver");
const fabric = require("fabric");
//const flat  = require('flat');
//const jsonminify = require('jsonminify');
const jsonpack = require('jsonpack');
const lzstring = require('lz-string');

require('@tensorflow/tfjs-backend-cpu')
require('@tensorflow/tfjs-backend-webgl')
const tf       = require("@tensorflow/tfjs");
const cocoSsd  = require('@tensorflow-models/coco-ssd') 

const helper   = require("./helper");
const dataset  = require("./dataset");
const event    = require("./event");
const other    = require("./other");

const image = { 
   get: async function(imageId) { 
      let image = {}
      let snapshot = await $_firebase.firestore().collection('image').doc(imageId).get(); 
      image = snapshot.data(); if(image)image.id = snapshot.id
      if(image)image.createdDate = helper.getTimestampDate(image.date,'full')
      if(image && image["updatedAt"])image.updatedDate = helper.getTimestampDate(image["updatedAt"].toDate(),'full')
      else if (image)image.updatedDate = helper.getTimestampDate(image.date,'full')
      return image;
   },

   getStorageUri: async function(imageId) { 
      let item = await this.get(imageId)
      return { uri: item && item.uri ? item.uri : "NOT FOUND", error: item && item.uri ? false : true };
   },

   getStorageUrl: async function(uri) { 
      let url = { uri: uri, url: false, error: false }
      try { 
         let promises = [$_firebase.storage().refFromURL(uri).getDownloadURL(), $_firebase.storage().refFromURL(uri).getMetadata()] //
         let results  = await Promise.all(promises)
         url.url      = results[0]
         url.meta     = results[1]
      } catch (error) { url.error = error }
      return url
   },

   delete: async function(imageId) { 
      const event   = require("./event");
      await this.deleteStorage(imageId)
      await $_firebase.firestore().collection('image').doc(imageId).delete().then( async() => {
         await event.saveEvent('image.delete',{ imageId: imageId }, false); //uid: useStore().state.main.User.uid,
         return { error: false, status: "success" }
     })
   },

   deleteStorage: async function(imageId) { 
      const project     = require("./project");
      let uri         = await this.getStorageUri(imageId)
      let config      = project.getConfig()
      if(uri.uri){
         let imageUri    = uri.uri.replace(config.modelBucket + "/", "").replace(/\//g, "--")
         await other.httpsCallable('api/image/delete/image/'+imageUri)
      }
   },
   
   getSet: async function(imageId) { 
      let item = await this.get(imageId)
      return item && item.set ? item.set : "PREDETERMINED";
   },
   
   setSet: async function(imageId, set) { 
      const event   = require("./event");
      if(!imageId || !set)return { error: "ImageId and set are required", status: "error" }
      await $_firebase.firestore().collection("image").doc(imageId.toString()).update({ "set": set });
      await event.saveEvent('image.update',{ imageId: imageId, set: set }, false); //uid: useStore().state.main.User.uid,
      return { error: false, status: "success" }
   },
   
   updateMask: async function(doc, mask) { 
      var resp        = { error: false, status: "success" }
      try { 
         console.log('Uncompressed JSON size:', JSON.stringify(mask.imageJson).length + ' bytes ('+ parseFloat(JSON.stringify(mask.imageJson).length/1024).toFixed(2)+ 'KB)');
         await $_firebase.firestore().collection("image").doc(doc.toString()).set({ "mask": mask }, { merge: true });
         return resp
      } catch (e) {
         try {
            mask.imageJson = lzstring.compressToUint8Array(JSON.stringify(mask.imageJson))
            console.log('Compressed with lzstring and save JSON size:', mask.imageJson.length + ' bytes ('+ parseFloat(mask.imageJson.length/1024).toFixed(2)+ 'KB)');
            mask.imageJson = $_firebase.firestore.Blob.fromUint8Array(mask.imageJson)
            console.log('firestore blob fromUint8Array:',mask.imageJson + ' bytes ('+ parseFloat(mask.imageJson/1024).toFixed(2)+ 'KB)');
            await $_firebase.firestore().collection("image").doc(doc.toString()).set({ "mask": mask }, { merge: true });  
            return resp
         } catch (er) {
            console.log('Error saving mask and compressed mask:', er);
            resp.error     = true
            resp.status    = "error"
            return resp
         }
      }  
   },

   updateMaskStorage: async function(imageId) { 
      const maskRef     = $_firebase.firestore().collection("image").doc(docId.toString());
      const maskBlob    = new Blob([maskUint8Array], { type: 'application/octet-stream' });
      const storageRef  = $_firebase.storage().ref();
      const maskFileRef = storageRef.child(`masks/${docId}/mask.bin`);
      await maskFileRef.put(maskBlob);
      const maskDownloadURL = await maskFileRef.getDownloadURL();
      await maskRef.set({ "mask": maskDownloadURL }, { merge: true });
   },

   getComments: async function(imageId) { 
      let item = await this.get(imageId)
      return { imageId: imageId, name: item.name, comments: item && item.comments ? item.comments : "" }
   },

   setComments: async function(imageId, comments) { 
      const event   = require("./event");
      if(!imageId)return { error: "ImageId and comments are required", status: "error" }
      await $_firebase.firestore().collection("image").doc(imageId.toString()).update({ "comments": comments });
      await event.saveEvent('image.update',{ imageId: imageId, comments: comments }, false); //uid: useStore().state.main.User.uid, 
      return { error: false, status: "success" }
   },

   getTags: async function(imageId) { 
      let item = await this.get(imageId)
      let resp = { image: imageId, dataset: null, tags: [] }
      if(item.updatedAt)resp.updatedAt = helper.getTimestampDate(item.updatedAt.toDate(),'full')
      if (item.tags && item.tags.length) {
          let tagsArr = []; let nameArr = []; let bbArr   = []; let colorArr   = []
          for (let obt in item.tags) {
              if (item.tags[obt].tag) {
                  let tagRef  = item.tags[obt].tag.path.toString().split('/')
                  if(tagRef[1])resp.dataset = tagRef[1]
                  let tagName = false
                  if (tagRef[tagRef.length-1])tagName = tagRef[tagRef.length-1]
                  if (tagName) { if (!tagsArr[tagName]) { tagsArr[tagName] = 1 } else { tagsArr[tagName]++; } }
                  let tagData = await dataset.getTag(tagRef[1], tagName)
                  if(tagData){
                   if(tagData.name && !nameArr[tagName])nameArr[tagName] = tagData.name
                   if(tagData.color && !colorArr[tagName])colorArr[tagName] = tagData.color
                  }
                  let bb = { 
                            h: item.tags[obt].h, 
                            w: item.tags[obt].w, 
                            x: item.tags[obt].x, 
                            y: item.tags[obt].y, 
                            type: item.tags[obt].type 
                           }
                  if (!bbArr[tagName])bbArr[tagName] = []; 
                  bbArr[tagName].push(bb)
              }
          }
          if (Object.keys(tagsArr).length) {
              for (let objectTag in tagsArr) { 
                resp.tags.push({ tag: objectTag, name: nameArr[objectTag] ? nameArr[objectTag]: objectTag, color: colorArr[objectTag] ? colorArr[objectTag]: "#000", count: tagsArr[objectTag], bounding_box: bbArr[objectTag] ? bbArr[objectTag]: [] }); 
             }
          } else { resp.response = "IMAGE NOT LABELED" } 
          return resp 
      } else {
          if(item.tag){
              let tagName = item.tag.path.toString().split('/')
              let tags    = []
              let tagData = await dataset.getTag(tagName[1], tagName[3])
              tags.push({ tag: tagName[3] , name: tagData.name, color: tagData.color , count: 1 })
              resp.dataset = tagName[1]
              resp.tags    = tags
              return resp
          } else { resp.response = "IMAGE NOT LABELED"; return resp }
      }
   },

   setTag: async function(imageId,datasetID,tagID) { 
      let tagRef = $_firebase.firestore().collection("dataset").doc(datasetID.toString()).collection('tag').doc(tagID.toString())
      let data   = { tag: tagRef, updatedAt: $_firebase.firebase.firestore.FieldValue.serverTimestamp() }
      await $_firebase.firestore().collection("image").doc(imageId.toString()).update(data);
   },

   setObjectTags: async function(imageId, tags) { 
      await $_firebase.firestore().collection('image').doc(imageId.toString()).update({ "tags": tags, updatedAt: $_firebase.firebase.firestore.FieldValue.serverTimestamp() });
   },

   setMultilabelTags: async function(imageId, tags, tagsContained) { 
      await $_firebase.firestore().collection('image').doc(imageId.toString()).update({ "tags": tags, "tagsContained": tagsContained, updatedAt: $_firebase.firebase.firestore.FieldValue.serverTimestamp() });
   },

   setTags: async function(imageId, tags) { 
      const dataset = require("./dataset");
      console.log(tags)
      let resp = { status: "error", error: false}
      if(imageId && tags){
         let item = await this.get(imageId)
         if(item.id){
            resp.image       = imageId
            let _dataset     = item.dataset.path.toString().split('/').pop()
            if(_dataset){
               if(Array.isArray(tags)){
                  if(tags.length){
                     let datasetHaveTags = []
                     let newImageTags    = []   
                     for (var i = 0; i < tags.length; i++) { 
                        let datasetTags  = await dataset.getTags(_dataset)
                        let pushTag      = true
                        if(!datasetTags[tags[i].tag]){ 
                           let created = await dataset.createTag(_dataset, { tag: tags[i].tag })
                           if(!created.status || created.status!="success"){ pushTag = false; datasetHaveTags.push(tags[i].tag) }
                        }
                        if(pushTag){
                           tags[i].tag = await $_firebase.firestore().collection("dataset").doc(_dataset).collection('tag').doc(tags[i].tag)
                           newImageTags.push(tags[i]) 
                        }
                     }   
                     if(!datasetHaveTags.length){
                        await $_firebase.firestore().collection('image').doc(imageId).update({ "tags": newImageTags, updatedAt: $_firebase.firebase.firestore.FieldValue.serverTimestamp() });
                        resp.status = "success"   
                     }else{ resp.error = "the tags "+JSON.stringify(datasetHaveTags)+" not found in "+_dataset+" dataset" }
                  }else{ resp.error = "new tags is empty" }
               }else{
                  let datasetTags  = await dataset.getTags(_dataset)
                  let pushTag      = true
                  if(!datasetTags[tags]){
                     let created = await dataset.createTag(_dataset, { tag: tags })
                     if(!created.status || created.status!="success")pushTag = false;  
                  }
                  if(pushTag){
                     let tagRef = await $_firebase.firestore().collection("dataset").doc(_dataset).collection('tag').doc(tags)
                     await $_firebase.firestore().collection("image").doc(imageId).update({ tag: tagRef, updatedAt: $_firebase.firebase.firestore.FieldValue.serverTimestamp() })
                        .then(function () { resp.status = "success" })
                        .catch(async (error) => { resp.status = error });
                  }else{ resp.error = "new tag "+tags.toString()+" not found in "+_dataset+" dataset"  }
               }
            }else{ resp.error = "image is not associated with any dataset" }
            resp.currentTags   = await this.getTags(imageId)
         } else { resp.error = "image not found" } 
      } else { resp.error = "image id and new tags are required" }
      return resp 
   },

   removeTags: async function(imageId) { 
      let resp   = { status: "error", error: false }
      if(imageId){
         resp.image = imageId
         let item   = await this.get(imageId)
         if(item.dataset){
            let _dataset = item.dataset.path.toString().split('/')
            if(_dataset[1]){
               let datasetRef = await $_firebase.firestore().collection("dataset").doc(_dataset[1].toString()).collection('tag').doc("0") 
               await $_firebase.firestore().collection("image").doc(imageId)
                     .update({ tag: datasetRef, tags: [], tagsContained: [], updatedAt: $_firebase.firebase.firestore.FieldValue.serverTimestamp() });
               resp.status        = "success"  
               resp.currentTags   = await this.getTags(imageId)   
            }else{ resp.error = "image is not associated with any dataset" }      
         }   
      } else { resp.error = "image id is required" }
      return resp
   },

   detect: async function(imageId) { 
      let item   = await this.get(imageId)
      let detect = { status: "", error: false }
      if(item.uri){
      detect.url = await this.getStorageUrl(item.uri)
      if(detect.url){
         await this.detectCoco(detect.url.url).then( async (p) => { 
            detect.predictions = p
            if(detect.predictions && !detect.predictions.error){ detect.status = "success"; 
            } else { detect.status = "error"; detect.error = "no predictions" }
         });
      }   
      }else{ detect.status = "error"; detect.error = "storage path not found" }
      return detect
   },

   detectCoco: async function(url) { 
      return new Promise((resolve, reject) => {
         let img = new Image()
         img.crossOrigin = "anonymous"
         img.setAttribute('crossOrigin', 'anonymous');
         img.src = url
         let predictions = []
         img.onload = async () => {
            if (!(img instanceof tf.Tensor)) img = tf.browser.fromPixels(img);
            const model = await cocoSsd.load().catch(async (error) => { console.log(error) });
            predictions = await model.detect(img).catch(async (error) => { console.log(error) });
            resolve(predictions)
         }
         img.onerror = reject
        })
   },

   download: async function(imageId) { 
      let resp    = { error:  false,  status: "error" }
      let zip     = { name: "", files: [], folders: [], date: new Date() }
      let images  = []
      if(Array.isArray(imageId)){
         images = imageId
         zip.name = "selected_"+zip.date.getTime()+".zip"
      }else{ 
         zip.name = imageId.replace(/\s+/g, '_')+"_"+zip.date.getTime()+".zip"
         images.push(imageId)
      }
      var z = new JSZip()   
      for (var i = 0; i < images.length; i++) {
         let item       = await this.get(images[i])
         if(item.uri){
            let storageUrl = await this.getStorageUrl(item.uri)
            if(item.uri && storageUrl.url){
               zip.files.push({ 
                  name:  item.uri.substr(item.uri.lastIndexOf("/")+1).replace(/\s+/g, '_'),
                  blob:  fetch(storageUrl.url).then(response => response.blob()),
               })
            }
         }
      }
      zip.folders['images'] = z.folder('images')
      for (var zi = 0; zi < zip.files.length; zi++) { zip.folders['images'].file(zip.files[zi].name, zip.files[zi].blob, { base64: true }) }
      await z.generateAsync({type:"blob"}).then(async function (blob) { saveAs(blob,zip.name); });
      resp.status    = "success"
      resp.name      = zip.name
      resp.images    = images
      resp.message   = "The download will start automatically"
      return resp
   },

   previewB64: async function(imageId) { 
      let resp   = { error:  false,  status: "error" }
      if(imageId){
         let image  = await this.get(imageId)
         if(image.imageData){ 
            resp.image = imageId
            resp.b64   = 'data:image/png;base64,'+ image.imageData.toBase64()
            resp.status = "success"
         } else { resp.error = "Image not available in base64 (imageData)" }      
      } else { resp.error = "image id is required" }
      return resp
   }, 
   
   preview: async function(imageId, opt = false) { 
      let resp   = { error:  false,  status: "error" }
      if(imageId){
      resp.image = imageId
      let image  = await this.get(imageId)
      if(opt)resp.options = opt
      if(image.imageData){ 

         resp.canvas =  {
                        id:          "canvas_" + imageId,
                        name:        image.name,
                        image:       'data:image/png;base64,'+ image.imageData.toBase64(),
                        options:     { selectionLineWidth: 2, centeredScaling: false, transparentCorners: true },
                        contentType: "image/" + image.name.split('.').pop(),
                        size:        {},
                        objects:     { text: [], bbox: [] },
                        }

         if(opt.storage && image.uri){
            let path = await this.getStorageUrl(image.uri)
            if(path.url)resp.canvas.image = path.url
            if(path.uri)resp.canvas.uri   = path.uri
            if(path.meta)resp.canvas.contentType = path.meta.contentType
         }
         
         if(opt.width || opt.height){ 
            if(opt.width)resp.canvas.size.width   = opt.width
            if(opt.height)resp.canvas.size.height = opt.height
         }else{resp.canvas.size = await this.getDimensions(resp.canvas.image) } 

         if(opt.tags){ 
            resp.currentTags = await this.getTags(imageId)
            if(resp.currentTags.tags.length){
               for (var i = 0; i < resp.currentTags.tags.length; i++) {
                  if(resp.currentTags.tags[i]){

                     if(resp.currentTags.tags[i].bounding_box){
                        for (var b = 0; b < resp.currentTags.tags[i].bounding_box.length; b++) {
                           if(resp.currentTags.tags[i].bounding_box[b]){
                              if(resp.currentTags.tags[i].bounding_box[b].type=="rect"){
                                 resp.canvas.objects.bbox.push(
                                    new fabric.Rect({
                                                   left:     resp.currentTags.tags[i].bounding_box[b].x * resp.canvas.size.width,
                                                   top:      resp.currentTags.tags[i].bounding_box[b].y * resp.canvas.size.height,
                                                   originX:  'left',
                                                   originY:  'top',
                                                   width:    resp.currentTags.tags[i].bounding_box[b].w*resp.canvas.size.width,
                                                   height:   resp.currentTags.tags[i].bounding_box[b].h*resp.canvas.size.height,
                                                   angle:    0,
                                                   fill:     'transparent',
                                                   stroke:   resp.currentTags.tags[i].color,
                                                   strokeWidth: 1,
                                                   transparentCorners: false,
                                                   hoverCursor: "default",
                                                   selectable: false
                                                   }
                                    )
                                 )
                                 resp.canvas.objects.text.push(new fabric.Text(resp.currentTags.tags[i].name, { 
                                                   fill:     resp.currentTags.tags[i].color,
                                                   left:     (resp.currentTags.tags[i].bounding_box[b].x * resp.canvas.size.width),
                                                   top:      (resp.currentTags.tags[i].bounding_box[b].y * resp.canvas.size.height)-20,
                                                   fontSize: 18,
                                                   padding:  5,
                                                   hoverCursor: "default",
                                                   selectable: false
                                                   })) 
                              }
                           }
                        }
                     }else{
                        resp.canvas.objects.text.push(new fabric.Rect({
                                                                        fill: "#000",
                                                                        left: -1,
                                                                        top:  -1,
                                                                        width: resp.canvas.size.width+2,
                                                                        height:25,
                                                                        selectable: false,
                                                                        opacity: 0.6,
                                                                        hoverCursor: "default"
                                                                     })) 
                        resp.canvas.objects.text.push(new fabric.Text(resp.currentTags.tags[i].name, { 
                                                                        fill:     resp.currentTags.tags[i].color,
                                                                        left:     2,
                                                                        top:      2,
                                                                        fontSize: 16,
                                                                        padding:  5,
                                                                        hoverCursor: "default",
                                                                        selectable: false
                                                                        })) 
                     }
                  }
               }
            }
         }

         resp.status  = "success"   
      } else { resp.error = "Image not available in base64 (imageData)" }        
      } else { resp.error = "image id is required" }
      return resp
   }, 

   getPrev: async function(imageId , opt = {}) { 
      let prev      = false
      let item      = await this.get(imageId)
      let images    = $_firebase.firestore().collection('image')
      let datasetId = item.dataset.path.toString().split("/").pop() 
      let dsRef     = $_firebase.firestore().collection("dataset").doc(datasetId)
      let order     = "date"
      if(opt.order)order = opt.order
      images        = images.where("dataset", "==", dsRef).where("date", ">=", item.date)
      if(order=='tag' && opt.tag){
         let _tagRef  = $_firebase.firestore().collection("dataset").doc(datasetId).collection('tag').doc(opt.tag.toString())
         images       = images.where('tag', '==', _tagRef)
      }  
      await images.orderBy("date", "asc").limit(2).get().then((querySnapshot) => {
         querySnapshot.forEach((doc) => {
            if(!prev && doc.id!=imageId)prev =  doc.id
         })
      })
      return prev
   },

   getNext: async function(imageId, opt = {}) {
      let next      = false
      let item      = await this.get(imageId)
      let images    = $_firebase.firestore().collection('image')
      let datasetId = item.dataset.path.toString().split("/").pop() 
      let dsRef     = $_firebase.firestore().collection("dataset").doc(datasetId)
      let order     = "date"
      if(opt.order)order = opt.order
      images        = images.where("dataset", "==", dsRef).where("date", "<=", item.date)
      if(order=='tag' && opt.tag){
         let _tagRef  = $_firebase.firestore().collection("dataset").doc(datasetId).collection('tag').doc(opt.tag.toString())
         images       = images.where('tag', '==', _tagRef)
         
      }
      await images.orderBy("date", "desc").limit(2).get().then((querySnapshot) => {
         querySnapshot.forEach((doc) => {
            if(!next && doc.id!=imageId)next =  doc.id
         })
      })
      return next
   },

   navControls: async function(imageId, opt = {}) {
      let resp    = { status: "success", error: false }
      resp.prev   = await this.getPrev(imageId, opt)
      resp.next   = await this.getNext(imageId, opt)
      return resp
   },

   copy: async function(imageId, opt = {}) {    
      const dataset = require("./dataset");
      let resp = { status: "error", error: false };
      try {
        if (!imageId)throw new Error("image id is required");
        if (!opt.dataset)throw new Error("destination dataset is required");
        if (!opt.tag)opt.tag = "0";
        let imgSrcUri   = await this.getStorageUri(imageId);
        let fileName    = imgSrcUri.uri.toString().split('/').pop();
        let url         = await $_firebase.storage().refFromURL(imgSrcUri.uri).getDownloadURL();
        let response    = await fetch(url);
        let blob        = await response.blob();
        var nowDate     = new Date();
        let createImage = await dataset.uploadImage({ 
                                             datasetId: opt.dataset, 
                                             image:     blob, 
                                             name:      nowDate.getTime() + "." + fileName.toString().split('.').pop(),
                                             tagId:     opt.tag,
                                             comments:  "Copied from " + imageId
                                          });
        if(!createImage.error){
            resp.status = "success";
            resp.id     = createImage.uploadedId;
            if(opt.mask)await this.copyMask(imageId, resp.id, { tag: opt.tag })
            return resp;
        }else{
         resp.error = "Error copying image, " + createImage.error;
         throw resp;
        }
       
      } catch (error) {
        resp.error = "Error copying image, " + error;
        throw resp;
      }
    },
   
   copyMask: async function(srcImageId,toImageId, opt = {}) { 
      const { fabric } = require("fabric/dist/fabric");
      let resp     = { status: "error", error: false };
      let srcImg   = await this.get(srcImageId)
      let srcTo    = await this.get(toImageId)
      if(srcImg.mask && srcImg.mask.imageJson){
         if(opt.tag){
            await $_firebase.firestore().collection("dataset").doc(srcTo.dataset.path.toString().split("/").pop()).collection('tag').doc(opt.tag.toString()).get().then((doc) => { 
               let newTag =  doc.data() 
               if(newTag.color){
                  let maskJSON  = null
                  if(srcImg.mask.imageJson){
                     var canvasElement = document.createElement('canvas');
                     canvasElement.setAttribute('id', 'canvas');
                     canvasElement.setAttribute('width', srcImg.mask.width);
                     canvasElement.setAttribute('height', srcImg.mask.height);
                     let saveCanvas  = new fabric.Canvas(canvasElement, { });
                     try { maskJSON = JSON.parse(srcImg.mask.imageJson)
                     } catch (error) { maskJSON = JSON.parse(lzstring.decompressFromUint8Array(srcImg.mask.imageJson.toUint8Array())) ; }
                     saveCanvas.loadFromJSON(srcImg.mask.imageJson, function() {
                     }, function(o, object) {
                        object.set({stroke: newTag.color , color: newTag.color , shadow: null});
                     });
                     setTimeout(async () => {
                        let canvasJSON  = saveCanvas.toJSON();
                        await this.updateMask(toImageId, { imageJson: JSON.stringify(canvasJSON), width: srcImg.mask.width, height: srcImg.mask.height, color: newTag.color })
                        canvasElement.remove();
                        resp.status = "success";
                     }, 500);
                  }
               }else resp.error = "New tag color is required"
            })
         }else resp.error = "New mask tag is required"
      }else resp.error = "Source image has no mask"
      return resp;
   },

   getDimensions: async function(url) { 
      return new Promise((resolve, reject) => {
         let img    = new Image()
         img.crossOrigin = "anonymous"
         img.setAttribute('crossOrigin', 'anonymous');
         img.src = url
         img.onload = async (i) => { resolve({ width: i.target.width, height: i.target.height }) }
         img.onerror = reject
        })
   }, 

   randomImages: async function(opt = false) { 
      let images = [];
      const dataset = require("./dataset");
      let datasets = await dataset.list()
      for (var i = 0; i < Object.keys(datasets).length; i++) {
         let datasetImages = await dataset.getImages({ datasetID: datasets[i].id, limit: 5 });
         if(datasetImages.media)images = images.concat(datasetImages.media);
         if(opt.limit && images.length>=opt.limit)break
      }
      return images
   }, 

   sortImagesByDate: async function(images, p) { 
      return images.sort((docA, docB) => {
         return helper.dateStringToMillis(docB[p]) - helper.dateStringToMillis(docA[p]);
      });
   }, 

   getb64: async function(Url) { 
      try {
         const response = await fetch(Url);
         const buffer   = await response.arrayBuffer();
         return 'data:image/png;base64,' + helper.arrayBufferToBase64(buffer);
      } catch (error) {
         throw error;
      }
   },

}

module.exports = image